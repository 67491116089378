import { Injectable } from '@angular/core';
import {
  APIService,
  ListCie10sQuery,
  ModelCIE10FilterInput,
  SearchableCIE10FilterInput,
  SearchableCIE10SortableFields,
  SearchableSortDirection,
  SearchCie10sQuery,
} from '../services/API.service';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root',
})
export class CIE10Service {
  constructor(private api: APIService, private location: LocationService) {}

  listCIE10(word: string): Promise<SearchCie10sQuery> {
    const filter: SearchableCIE10FilterInput = {
      or: [
        { code: { eq: word.toUpperCase() } },
        { description: { match: word } },
      ],
    };
    return this.api.SearchCie10s(
      filter,
      {
        field: SearchableCIE10SortableFields.code,
        direction: SearchableSortDirection.asc,
      },
      100
    );
  }

}
