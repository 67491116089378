import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { ConsultService } from 'src/app/services/consult.service';
import { SisecService } from 'src/app/services/sisec.service';
const logger = new Logger('medical-consultations');
@Component({
  selector: 'app-medical-consultations',
  templateUrl: './medical-consultations.component.html',
  styleUrls: ['./medical-consultations.component.scss'],
})
export class MedicalConsultationsComponent {
  @ViewChild('form') form: NgForm;
  studies: any[] = [];
  selectedCie10: string = '';
  selectedCenter: any = '';
  cie10s: any[] = [];
  cie10: any[] = [];
  centers: any[] = [];

  constructor(
    private sisec: SisecService,
    private messageService: MessageService,
    private consultService: ConsultService
  ) {
  }

  async ngOnInit() {
    try {
      this.sisec.showSpinner('Cargando modulo...');
      this.centers = await this.consultService.getCenters();
      this.consultService.searchCie10().
        subscribe((data: any) => {
          this.cie10 = data;
          logger.debug(this.cie10, 'cie10');
          this.sisec.hideSpinner();
        }, () => {
          this.errorMessage();
          this.sisec.hideSpinner();
        })
    } catch (error) {
      this.errorMessage();
    }
  }

  searchCIE10(event): void {
    const word = event.query.toString().trim();
    if (word.length > 1) {
      this.cie10s = this.consultService.filterCie10(this.cie10, word).map((cie10: any) => {
        return this.sisec.truncateString(
          `${cie10.code}` + ' - ' + `${cie10.description}`,
          160
        );
      });
    }
  }

  async onSubmit(form: NgForm) {
    let { cie10, name} = form.value;
    let center = this.selectedCenter?.nombre || '';

    //Si el usuario no ingresa datos o se limpio se colocan en vacio
    cie10 = cie10?.split('-')[0] || '';
    name = name || '';

    if (cie10?.length === 0 && name?.length === 0 && center?.length === 0) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'warn',
        summary: 'Formulario inválido',
        detail: 'Debe llenar al menos un campo para consultar un estudio',
        life: 3000
      })
    } else {
      this.sisec.showSpinner('Buscando estudios...');
      //Llama a la funcion del servicio que busca los estudios segun los parametros enviados
      this.studies = await this.consultService.findStudies(cie10?.trim() || '', name?.trim() || '', center || '');
      this.sisec.hideSpinner();
    }
  }

  clear() {
    this.clearCenter();
    this.form.reset();
  }

  clearCenter() {
    this.selectedCenter = '';
  }

  errorMessage() {
    this.messageService.clear();
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Error al consultar la información',
        detail: 'Hubo un error, contacte al administrador.'
      });
  }
}
