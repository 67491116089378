import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
@Injectable({
  providedIn: 'root'
})
export class ConsultService {

  cie10: any[] = [];

  constructor(private http: HttpClient) { }



  searchCie10() {
    return this.http.get('../../assets/CIE10/cie10.json');
  }

  //Filtra los items por la palabra que envia el usuario
  filterCie10(items, word) {
    let wordClear = this.removeAccents(word.toLowerCase());
    return items.filter(x => {
      let description = this.removeAccents(x.description.toLowerCase());
      let code = this.removeAccents(x.code.toLowerCase());
      return description.includes(wordClear) || code.includes(wordClear);
    })
  }

  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }


  findStudies(cie10: string, name: string, center: string) {
    return API.post('getStudies', '/getStudies', { body: { cie10, name, center } });
  }

  getCenters() {
    return API.get('getStudies', '/getCenters', {});
  }

}
